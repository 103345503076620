module.exports = {
  applicationInfo:{
    // appName: "PGDSCM, BIM",
    appName: "Sus-plan",
    devOrgName: "Bangladesh Institute of Management (BIM)",
    devOrgLink: "http://www.bim.gov.bd/"
  },
  applicationSettings:{
    developmentBaseURL :  "https://api.sustainability.com.bd/api/",//"https://api.supplychain.com.bd/api/",//"https://braintechsolution.com:3335/api/",//"http://localhost:3334/api/",//"
    productionBaseURL : "https://api.sustainability.com.bd/api/",//"https://api.supplychain.com.bd/api/",//"https://api.sustainability.com.bd/api/",//"https://braintechsolution.com:3331/api/",//"http://localhost:3334/api/",//"
    session_name : "Supplychain",
  },
  zoomSettings:{
    baseURL : "http://localhost:3001/",
  }
}
