import React, {useState, useEffect, useRef} from "react"
import classnames from "classnames"
import {AvForm, AvField} from "availity-reactstrap-validation"
import {
    Col,
    Row,
    Table,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    CardText,
    Container,
    InputGroup,
    Collapse,
    Label,
    Button,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"

import Select from "react-select";

import carpetSteamClean from "../../assets/images/bookNow/Carpet Steam Clean.png"
import slidingGlass from "../../assets/images/bookNow/Sliding Glass Doors.png"
import smallBalcony from "../../assets/images/bookNow/Small Balcony.png"
import blindsShutters from "../../assets/images/bookNow/Blinds Shutters (per fixture).png"

import './model.scss'

// Carousel
import Slidewithfade from "./CarouselTypes/slidewithfade"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"

import {useHistory, Link} from "react-router-dom"

const Model = (props) => {

    const [activeTab,
        setactiveTab] = useState(1);
    const [noPaddingStyle,
        setNoPaddingStyle] = useState({});

    const [col1,
        setcol1] = useState(false)
    const [col2,
        setcol2] = useState(false)
    const [col3,
        setcol3] = useState(false)

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 6) {
                setactiveTab(tab)
            }
        }
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col sm={12} lg={12} className="align-items-center">
                    <div
                        className="page-content"
                        style={{
                        marginTop: "0",
                        paddingTop: "0"
                    }}>
                        <Row>
                            <Col
                                md={12}
                                style={{
                                textAlign: "center"
                            }}
                                className="mb-5">
                                <h1>Book Online in 60 minutes</h1>
                                <p>View pricing, availability & book online in 60 seconds.</p>
                            </Col>
                            <Col md={8}>
                                <Card>
                                    <CardBody style={noPaddingStyle}>
                                        {!props.id && !noPaddingStyle && <CardTitle typeof="h4">REGISTRATION</CardTitle>}
                                        <div className="form-wizard-wrapper wizard clearfix">
                                            <div
                                                className="steps customSteps clearfix"
                                                style={{
                                                display: "none"
                                            }}>
                                                <ul>
                                                    <NavItem
                                                        className={classnames({
                                                        current: activeTab === 1
                                                    })}>
                                                        <NavLink
                                                            className={classnames({
                                                            current: activeTab === 1
                                                        })}
                                                            onClick={() => {
                                                            setactiveTab(1)
                                                        }}>
                                                            {!noPaddingStyle && <span className="number">1</span>}
                                                            General
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                        current: activeTab === 2
                                                    })}>
                                                        <NavLink
                                                            className={classnames({
                                                            active: activeTab === 2
                                                        })}
                                                            onClick={() => {
                                                            setactiveTab(2)
                                                        }}>
                                                            {!noPaddingStyle && <span className="number">2</span>}
                                                            {" "}
                                                            Education
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </div>
                                            <div className="content clearfix">
                                                <TabContent activeTab={activeTab} className="body">
                                                    <TabPane tabId={1}>

                                                        <Card>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 1: Choose your service</CardTitle>
                                                            <CardText>
                                                                With supporting text below as a natural lead-in to additional content.
                                                            </CardText>
                                                            {/* <Label>Single Select</Label> */}
                                                            <Select
                                                                value={[]}
                                                                onChange={() => {}}
                                                                options={[]}
                                                                classNamePrefix="select2-selection"/>
                                                            <br/>
                                                            <br/>
                                                            <CardTitle className="h4">Frequency</CardTitle>
                                                            <p className="card-title-desc"></p>
                                                            <div className="button-items">
                                                                <Button color="primary" outline className="waves-effect waves-light">
                                                                    One-off
                                                                </Button>{" "}
                                                                <Button color="secondary" outline className="waves-effect">
                                                                    Weekly-15% off
                                                                </Button>{" "}
                                                                <Button color="success" outline className="waves-effect waves-light">
                                                                    Fornightly-10% off
                                                                </Button>{" "}
                                                                <Button color="info" outline className="waves-effect waves-light">
                                                                    Monthly-5% off
                                                                </Button>{" "}
                                                            </div>
                                                        </Card>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 2: How big is your home?</CardTitle>
                                                            <CardText>
                                                                With supporting text below as a natural lead-in to additional content.
                                                            </CardText>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Label>Bedrooms</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Bathrooms</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Kitchen</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Living Areas</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Levels</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Laundry</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>
                                                            </Row>
                                                        </Card>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 3: Select your add-ons</CardTitle>
                                                            <CardText>
                                                                Select the extras you wish to add to your booking.
                                                            </CardText>
                                                            <Row className="addonsdiv">
                                                                <Col sm={6} md={6} lg={3}>
                                                                    <Card outline color="primary" className="border">
                                                                        <CardImg top className="img-fluid" src={slidingGlass} alt="Card image cap"/>
                                                                    </Card>
                                                                    <InputGroup>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="btn btn-sm" color="primary">
                                                                                <i className="mdi mdi-minus"/>
                                                                            </Button>
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={0}
                                                                            placeholder="number"
                                                                            style={{
                                                                            textAlign: "center"
                                                                        }}
                                                                            readOnly/>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="button" color="primary">
                                                                                <i className="mdi mdi-plus"/>
                                                                            </Button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <CardTitle
                                                                        className="h3 mt-2"
                                                                        style={{
                                                                        textAlign: "center"
                                                                    }}>Sliding Glass Doors</CardTitle>
                                                                </Col>

                                                                <Col sm={6} md={6} lg={3}>
                                                                    <Card outline color="primary" className="border">
                                                                        <CardImg top className="img-fluid" src={carpetSteamClean} alt="Card image cap"/>
                                                                    </Card>
                                                                    <InputGroup>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="btn btn-sm" color="primary">
                                                                                <i className="mdi mdi-minus"/>
                                                                            </Button>
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={0}
                                                                            placeholder="number"
                                                                            style={{
                                                                            textAlign: "center"
                                                                        }}
                                                                            readOnly/>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="button" color="primary">
                                                                                <i className="mdi mdi-plus"/>
                                                                            </Button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <CardTitle
                                                                        className="h3 mt-2"
                                                                        style={{
                                                                        textAlign: "center"
                                                                    }}>Carpet Steam Clean extras</CardTitle>
                                                                </Col>

                                                                <Col mg={6} lg={3}>
                                                                    <Card outline color="primary" className="border">
                                                                        <CardImg top className="img-fluid" src={blindsShutters} alt="Card image cap"/>
                                                                    </Card>
                                                                    <InputGroup>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="btn btn-sm" color="primary">
                                                                                <i className="mdi mdi-minus"/>
                                                                            </Button>
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={0}
                                                                            placeholder="number"
                                                                            style={{
                                                                            textAlign: "center"
                                                                        }}
                                                                            readOnly/>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="button" color="primary">
                                                                                <i className="mdi mdi-plus"/>
                                                                            </Button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <CardTitle
                                                                        className="h3 mt-2"
                                                                        style={{
                                                                        textAlign: "center"
                                                                    }}>Blinds/ Shutters (per fixture)</CardTitle>
                                                                </Col>

                                                                <Col mg={6} lg={3}>
                                                                    <Card outline color="primary" className="border">
                                                                        <CardImg top className="img-fluid" src={smallBalcony} alt="Card image cap"/>
                                                                    </Card>
                                                                    <InputGroup>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="btn btn-sm" color="primary">
                                                                                <i className="mdi mdi-minus"/>
                                                                            </Button>
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={0}
                                                                            placeholder="number"
                                                                            style={{
                                                                            textAlign: "center"
                                                                        }}
                                                                            readOnly/>
                                                                        <div className="input-group-append" onClick={() => {}}>
                                                                            <Button type="button" color="primary">
                                                                                <i className="mdi mdi-plus"/>
                                                                            </Button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <CardTitle
                                                                        className="h3 mt-2"
                                                                        style={{
                                                                        textAlign: "center"
                                                                    }}>Small Balcony</CardTitle>
                                                                </Col>

                                                            </Row>
                                                        </Card>
                                                    </TabPane>

                                                    <TabPane tabId={2}>

                                                        <div className="actions clearfix mb-2">
                                                            {activeTab != 1 && <Button
                                                                style={{
                                                                float: "left",
                                                                width: "100%"
                                                            }}
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                toggleTab(activeTab - 1)
                                                            }}>Previous</Button>
                                                        }
                                                        </div>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 4: When would you like us to arrive?</CardTitle>
                                                            <CardText>
                                                                Select the date and time you would like us to arrive. Once we receive your
                                                                booking, we'll match you with one of our top cleaning pros and we'll send you a
                                                                confirmation.<br/><br/>
                                                                Cancel or reschedule your appointment for free up to 24 hours in advance.
                                                            </CardText>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Label>Select Date</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>
                                                            </Row>
                                                        </Card>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 5: Contact Information</CardTitle>
                                                            <CardText>
                                                                This information will be used to contact you about your service.
                                                            </CardText>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Label>First Name</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>

                                                                <Col md={6}>
                                                                    <Label>Last Name</Label>
                                                                    <Select
                                                                        value={[]}
                                                                        onChange={() => {}}
                                                                        options={[]}
                                                                        classNamePrefix="select2-selection"/>
                                                                </Col>
                                                            </Row>
                                                        </Card>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 6: Any special notes or instructions?</CardTitle>

                                                            <Row>
                                                                <Col md={12}>
                                                                    <textarea
                                                                        rows={5}
                                                                        name="academicBiography"
                                                                        defaultValue={""}
                                                                        placeholder="Instructions"
                                                                        type="textarea"
                                                                        className="form-control"
                                                                        id="academicBiography"/>
                                                                </Col>
                                                            </Row>
                                                        </Card>

                                                        <Card body>
                                                            <CardTitle className="h3 mt-0 boldHeader">Step 7: Payment Details</CardTitle>
                                                            <CardText>
                                                                Enter your credit card information below. A hold will be placed on your card 48
                                                                hours before the service date, but you will only be charged after the service
                                                                has been completed.
                                                            </CardText>
                                                            <Row>
                                                                <Col md={12}></Col>
                                                            </Row>
                                                        </Card>
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                            <div className="actions clearfix">

                                                {activeTab === 2
                                                    ? <Button
                                                            style={{
                                                            width: "100%"
                                                        }}
                                                            color="primary"
                                                            className="btn btn-warning btn-lg btn-block waves-effect waves-light mb-1">
                                                            Submit
                                                        </Button>
                                                    : <Button
                                                        onClick={() => {
                                                        toggleTab(activeTab + 1)
                                                    }}
                                                        style={{
                                                        width: "100%"
                                                    }}
                                                        color="primary"
                                                        className="btn btn-warning btn-lg btn-block waves-effect waves-light mb-1">
                                                        Find availability
                                                    </Button>
}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card body>
                                    <CardTitle className="h4 mt-0 boldHeader">Booking Summary</CardTitle>
                                    <CardText>
                                        With supporting text below as a natural lead-in to additional content.
                                    </CardText>
                                    <div className="table-responsive bookNowTable">
                                        <Table className="table mb-0">

                                            <tbody>
                                                <tr>
                                                    <td>Service</td>
                                                    <td>:</td>
                                                    <td>End of Lease</td>
                                                </tr>
                                                <tr>
                                                    <td>Frequency</td>
                                                    <td>:</td>
                                                    <td>Monthly - 5% Off</td>
                                                </tr>
                                                <tr>
                                                    <td>Bedrooms</td>
                                                    <td>:</td>
                                                    <td>1 Bedroom</td>
                                                </tr>
                                                <tr>
                                                    <td>Bathrooms</td>
                                                    <td>:</td>
                                                    <td>1 Bathroom</td>
                                                </tr>
                                                <tr>
                                                    <td>Kitchen</td>
                                                    <td>:</td>
                                                    <td>1 Kitchen</td>
                                                </tr>
                                                <tr>
                                                    <td>Living Areas</td>
                                                    <td>:</td>
                                                    <td>1 Living / Dining</td>
                                                </tr>
                                                <tr>
                                                    <td>Levels</td>
                                                    <td>:</td>
                                                    <td>Single story</td>
                                                </tr>
                                                <tr>
                                                    <td>Laundry</td>
                                                    <td>:</td>
                                                    <td>0 Laundry Rooms</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold"
                                                    }}>Total before Tax</td>
                                                    <td
                                                        style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        color: "brown",
                                                        textAlign: "right"
                                                    }}>$140</td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        style={{
                                                        fontSize: "30px",
                                                        fontWeight: "bold"
                                                    }}>Total</td>
                                                    <td
                                                        style={{
                                                        fontSize: "30px",
                                                        fontWeight: "bold",
                                                        color: "red",
                                                        textAlign: "right"
                                                    }}>$140</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>

                                {/* <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Trusted by</CardTitle>
                                        <p className="card-title-desc">
                                            1000+ Sydney Homes
                                        </p>
                                        <Slidewithfade/>
                                    </CardBody>
                                </Card> */}

                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">FAQ</CardTitle>
                                        <p className="card-title-desc">
                                            Popular Questions.
                                        </p>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        onClick={() => {
                                                        setcol1(!col1)
                                                    }}
                                                        style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        Accordion Item #1
                                                    </button>
                                                </h2>
                                                <Collapse id="collapseOne" className="accordion-collapse" isOpen={col1}>
                                                    <div className="accordion-body">
                                                        <strong>This is the first item's accordion body.</strong>
                                                        It is hidden by default, until the collapse plugin adds the appropriate classes
                                                        that we use to style each element. These classes control the overall appearance,
                                                        as well as the showing and hiding via CSS transitions. You can modify any of
                                                        this with custom CSS or overriding our default variables. It's also worth noting
                                                        that just about any HTML can go within the
                                                        <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        onClick={() => {
                                                        setcol2(!col2)
                                                    }}
                                                        style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        Accordion Item #2
                                                    </button>
                                                </h2>
                                                <Collapse id="collapseTwo" className="accordion-collapse" isOpen={col2}>
                                                    <div className="accordion-body">
                                                        <strong>This is the second item's accordion body.</strong>
                                                        It is hidden by default, until the collapse plugin adds the appropriate classes
                                                        that we use to style each element. These classes control the overall appearance,
                                                        as well as the showing and hiding via CSS transitions. You can modify any of
                                                        this with custom CSS or overriding our default variables. It's also worth noting
                                                        that just about any HTML can go within the
                                                        <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        onClick={() => {
                                                        setcol3(!col3)
                                                    }}
                                                        style={{
                                                        cursor: "pointer"
                                                    }}>
                                                        Accordion Item #3
                                                    </button>
                                                </h2>
                                                <Collapse id="collapseThree" className="accordion-collapse" isOpen={col3}>
                                                    <div className="accordion-body">
                                                        <strong>This is the third item's accordion body.</strong>
                                                        It is hidden by default, until the collapse plugin adds the appropriate classes
                                                        that we use to style each element. These classes control the overall appearance,
                                                        as well as the showing and hiding via CSS transitions. You can modify any of
                                                        this with custom CSS or overriding our default variables. It's also worth noting
                                                        that just about any HTML can go within the
                                                        <code>.accordion-body</code>, though the transition does limit overflow.
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Model
